import React from 'react';
export default function Losses() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={23} viewBox="0 0 24 23" fill="none">
      <path
        d="M7 11.9843L12 16.5785L17 11.9843"
        stroke="#FF0000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5.55234L12 10.1466L17 5.55234"
        stroke="#FF0000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
