export default function Games() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 50 42" fill="none">
      <path
        d="M47.8335 4.07837V16.5784H35.3335"
        stroke="white"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 37.4116V24.9116H14.5"
        stroke="white"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22917 14.4951C8.28577 11.5092 10.0815 8.83966 12.4489 6.73553C14.8163 4.63139 17.6781 3.16125 20.7673 2.46228C23.8565 1.7633 27.0724 1.85828 30.115 2.73835C33.1576 3.61842 35.9276 5.25489 38.1667 7.49507L47.8333 16.5784M2 24.9117L11.6667 33.9951C13.9057 36.2353 16.6758 37.8717 19.7184 38.7518C22.7609 39.6319 25.9769 39.7268 29.0661 39.0279C32.1553 38.3289 35.0171 36.8588 37.3844 34.7546C39.7518 32.6505 41.5476 29.9809 42.6042 26.9951"
        stroke="white"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
