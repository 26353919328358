export default function SortAscIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      style={{ marginLeft: 5 }}
      viewBox="0 0 14 12"
      fill="none">
      <line y1="1" x2="14" y2="1" stroke="white" strokeWidth="2" />
      <path d="M1.3855 6H12.6147" stroke="white" strokeWidth="2" />
      <line x1="3.28125" y1="11" x2="11.0833" y2="11" stroke="white" strokeWidth="2" />
    </svg>
  );
}
