import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/layouts';
import { routes } from './routes';
import './styles/global.scss';
import { ROUTES } from './constant/routes';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer position="top-right" />

      <Layout>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.path == ROUTES.HOME ? (
                  <Navigate replace to={ROUTES.DASHBOARD} />
                ) : (
                  route.element
                )
              }
            />
          ))}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
