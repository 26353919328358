import React from 'react';
export default function Bets() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 23" fill="none">
      <path
        d="M21 14.9843V7.6335C20.9996 7.31124 20.9071 6.99473 20.7315 6.71572C20.556 6.43672 20.3037 6.20503 20 6.0439L13 2.3685C12.696 2.20721 12.3511 2.1223 12 2.1223C11.6489 2.1223 11.304 2.20721 11 2.3685L4 6.0439C3.69626 6.20503 3.44398 6.43672 3.26846 6.71572C3.09294 6.99473 3.00036 7.31124 3 7.6335V14.9843C3.00036 15.3066 3.09294 15.6231 3.26846 15.9021C3.44398 16.1811 3.69626 16.4128 4 16.5739L11 20.2493C11.304 20.4106 11.6489 20.4955 12 20.4955C12.3511 20.4955 12.696 20.4106 13 20.2493L20 16.5739C20.3037 16.4128 20.556 16.1811 20.7315 15.9021C20.9071 15.6231 20.9996 15.3066 21 14.9843Z"
        stroke="#2FD3DB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 4.15106L12 6.54007L16.5 4.15106"
        stroke="#2FD3DB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 18.4667V13.6979L3 11.3089"
        stroke="#2FD3DB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 11.3089L16.5 13.6979V18.4667"
        stroke="#2FD3DB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.27002 6.6779L12 11.3181L20.73 6.6779"
        stroke="#2FD3DB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20.5709V11.3089"
        stroke="#2FD3DB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
