import classNames from 'classnames/bind';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DATE_FORMAT } from '../../constant/date';
import stylesCommon from '../../styles/common.module.scss';
import { ROUTES } from '../../constant/routes';
import AdminTable from '../UI/AdminTable';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import useMetaverse from '../../hooks/useMetaverse';
import shortenAddress from '../../utils/shortenAddress';
import Tooltip from '../UI/Tooltip';
import ArrowRightCircle from '../Icons/ArrowRightCircle';

interface PropsType {
  // handleChangePage: (page: number) => void;
  deposits: Transaction[];
  filterParams: FilterType;
  // pagination: { page: number; pageSize: number; total: number };
}

interface FilterType {
  id: string | undefined;
  name: string | undefined;
  currency: string | undefined;
  network: string | undefined;
  amountFrom: string | undefined;
  amountTo: string | undefined;
  signedUpDateFrom: string | undefined;
  signedUpDateTo: string | undefined;
  search: string | undefined;
  status: string | undefined;
}

const initialFilter = {
  id: undefined,
  name: undefined,
  currency: undefined,
  network: undefined,
  amountFrom: undefined,
  amountTo: undefined,
  signedUpDateFrom: undefined,
  signedUpDateTo: undefined,
  search: undefined,
  status: undefined
};

const pageSize = 10;

const cxCommon = classNames.bind(stylesCommon);

const ListDeposits = ({ deposits, filterParams }: PropsType) => {
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const STATUS = {
    INVALID: {
      title: 'INVALID',
      color: '#FF0000B8'
    },
    VALIDATING: {
      title: 'VALIDATING',
      color: '#FFD700'
    },
    COMPLETED: {
      title: 'COMPLETED',
      color: '#50F900'
    }
  } as any;

  const columns = [
    {
      title: 'User Identifier',
      dataIndex: 'userIdentifier',
      key: 'userIdentifier'
    },
    // {
    //   title: 'Username',
    //   dataIndex: 'name',
    //   key: 'name',
    //   render: (name: string) => <div style={{ color: '#5C89FF' }}>{name}</div>
    // },

    {
      title: 'Currency',
      key: 'currency',
      dataIndex: 'currency'
    },
    {
      title: 'Network',
      key: 'network',
      dataIndex: 'network'
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: (amount: number) => <div style={{ color: '#50F900' }}>{amount}</div>
    },
    {
      title: 'Status',
      key: 'state',
      dataIndex: 'state',
      render: (state: string) => (
        <p style={{ color: STATUS[state]?.color }}>{STATUS[state]?.title}</p>
      )
    },
    {
      title: 'Transaction Hash',
      key: 'transactionHash',
      dataIndex: 'transactionHash',
      render: (transactionHash: string) => (
        <Tooltip content="Copied" direction="topf">
          <text
            onClick={() => {
              window.navigator.clipboard.writeText(transactionHash);
            }}
            style={{ cursor: 'pointer', color: '#5C89FF', textDecoration: 'underline' }}>
            {transactionHash ? (
              <>
                {shortenAddress(transactionHash)}
                <ArrowRightCircle />{' '}
              </>
            ) : (
              ''
            )}
          </text>
        </Tooltip>
      )
    },
    {
      title: 'Message',
      key: 'message',
      dataIndex: 'message'
    },

    {
      title: 'Created Date',
      key: 'createdDate',
      dataIndex: 'createdDate'
    },
    {
      title: '',
      key: 'detail',
      dataIndex: 'detail',
      render: (detail: string) => (
        <a href={`/deposits/${detail}`} style={{ textDecoration: 'underline' }}>
          {detail}
        </a>
      )
    }
  ] as any[];

  // const handleFilterSearch = (deposit: Transaction) => {
  //   if (filterParams.search) {
  //     const actionSearch = filterParams.search;
  //     return (
  //       deposit.id?.includes(actionSearch) ||
  //       deposit.fromAccount?.toLowerCase().includes(actionSearch.toLowerCase())
  //     );
  //   }
  //   return deposit;
  // };

  const updateDataTable = (page: number, filterParams: FilterType) => {
    let dataTable = deposits
      // .filter(handleFilterSearch)
      .filter((deposit) => (deposit.type = 'DEPOSIT'))
      .map((deposit) => {
        return {
          // key: deposit.id,
          // id: [deposit.id].join().slice(0, 3) + ' ... ' + [deposit.id].join().slice(33, 36),
          userIdentifier: deposit.toAccount,
          currency: deposit.assetSymbol,
          amount: deposit.assetAmount,
          state: deposit.state,
          transactionHash: deposit.hash,
          message: deposit.message,
          network: deposit.fromChain,
          createdDate: deposit.createdAt ? moment.unix(deposit.createdAt).format(DATE_FORMAT) : '',
          detail: (
            <a href={`${ROUTES.DEPOSITS}/${deposit.id}`}>
              <div className={cxCommon('pointer')}>Detail</div>
            </a>
          )
        };
      });
    // let total = deposits
    //   .filter(handleFilterSearch)
    //   .filter((deposit) => (deposit.type = 'DEPOSIT')).length;
    // setTotal(total);
    setDataTable(dataTable);
  };

  useEffect(() => {
    updateDataTable(page, filterParams);
  }, [JSON.stringify(deposits), page, filterParams]);

  const changePage = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <AdminTable
        columns={columns}
        dataTable={dataTable}
        pagination={{
          total,
          pageSize,
          page
        }}
        changePage={changePage}
      />
    </>
  );
};

export default ListDeposits;
