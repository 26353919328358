import classnames from 'classnames/bind';
import { ReactNode } from 'react';
import styles from '../../styles/layout.module.scss';
import Header from './Header';
import Sidebar from './Sidebar';

const cx = classnames.bind(styles);

interface PropsType {
  children: ReactNode;
}

export default function Layout({ children }: PropsType) {
  return (
    <div className={cx('layout-root')}>
      <div className={cx('layout-first-root')}>
        <Header />
        <div className={cx('layout-second-root')}>
          <Sidebar />
          <div className={cx('layout-second-content')}>{children}</div>
        </div>
      </div>
    </div>
  );
}
