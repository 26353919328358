export default function SortDescIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      style={{ marginLeft: 5 }}
      viewBox="0 0 14 12"
      fill="none">
      <line x1="10.7188" y1="1" x2="2.91667" y2="1" stroke="white" strokeWidth="2" />
      <line x1="14" y1="11" y2="11" stroke="white" strokeWidth="2" />
      <path d="M12.6145 6H1.38533" stroke="white" strokeWidth="2" />
    </svg>
  );
}
