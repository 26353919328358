import clasnames from 'classnames/bind';
import React from 'react';
import styles from '../../styles/UI/input.module.scss';

const cx = clasnames.bind(styles);

interface PropsType {
  placeholder?: string;
  expand?: React.ReactNode;
  readonly?: boolean;
}

function Input({ placeholder, expand, readonly = false, ...props }: PropsType, ref: any) {
  return (
    <div className={cx('input-container')}>
      <input
        readOnly={readonly}
        ref={ref}
        placeholder={placeholder || ''}
        className={cx('input')}
        {...props}
      />{' '}
      {expand && <div className={cx('input-expand-icon')}>{expand}</div>}
    </div>
  );
}

export default React.forwardRef(Input);
