import clasnames from 'classnames/bind';
import React from 'react';
import styles from '../../styles/UI/select.module.scss';

const cx = clasnames.bind(styles);

interface Option {
  id: number;
  key: string;
  value: string;
}

interface PropsType {
  placeholder?: string;
  options: Option[];
}

function Select({ placeholder, options, ...props }: PropsType, ref: any) {
  return (
    <div className={cx('select-container')}>
      <select className={cx('select')} ref={ref} {...props} placeholder={placeholder}>
        {options.map((option) => (
          <option key={option.key} value={option.id}>
            {option.value}
          </option>
        ))}
      </select>
    </div>
  );
}

export default React.forwardRef(Select);
