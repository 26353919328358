import classNames from 'classnames/bind';
import { Moment } from 'moment';
import React, { useState } from 'react';
import stylesCommon from '../../../styles/common.module.scss';
import styles from '../../../styles/Users/GamingHistory.module.scss';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);

interface Option {
  id: number;
  key: string;
  value: string;
}

type FieldType = 'span' | 'select';
type LogoType = 'svg';

interface FormItem {
  name: string;
  label: string;
  fieldType: FieldType;
  icon: LogoType;
  coinNumber: number;
  labelSpan: {
    md: number | string;
    xs: number | string;
  };
  itemSpan: {
    md: number | string;
    xs: number | string;
  };
  options?: Option[];
  onChangeDate?: (date: Moment) => void;
}

interface PropsType {
  formItems: any;
}

export default function FilterLayout({ formItems }: PropsType) {
  const renderFormItem = (item: FormItem) => {
    switch (item.fieldType) {
      case 'span': {
        return (
          <div className={cx('admin-card-span')}>
            {item.icon}
            {item.coinNumber}
          </div>
        );
      }
      case 'select': {
        return (
          <div className={cx('admin-card-select')}>
            {item.icon}
            <select>
              <option>{item.coinNumber}</option>
            </select>
          </div>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className={cx('container')}>
      <text>Gaming History</text>
      <div>
        {' '}
        {Object.keys(formItems).map((trNumber) => (
          <div className={cx('container-gaming')}>
            <div className={cx('container-text')}>
              {' '}
              <tr>{formItems[trNumber]?.span}</tr>
            </div>
            <tr>
              <td>
                {' '}
                <div className={cx('container-col')}>
                  {formItems[trNumber]?.col1?.map((formItem: any) => (
                    <React.Fragment key={formItem.name}>
                      <td>
                        <div className={cx('container-card')}>
                          <p>{formItem.label}</p>
                          {renderFormItem(formItem)}
                        </div>
                      </td>
                    </React.Fragment>
                  ))}
                </div>
              </td>
              <td>
                {' '}
                <div className={cx('container-col')}>
                  {formItems[trNumber]?.col2?.map((formItem: any) => (
                    <React.Fragment key={formItem.name}>
                      <td>
                        <div className={cx('container-card')}>
                          <p>{formItem.label}</p>
                          {renderFormItem(formItem)}
                        </div>
                      </td>
                    </React.Fragment>
                  ))}
                </div>
              </td>
            </tr>
          </div>
        ))}
      </div>
    </div>
  );
}
