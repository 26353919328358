import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from '../../styles/UI/tooltip.module.scss';

const cx = classNames.bind(styles);

const Tooltip = (props: any) => {
  const [active, setActive] = useState(false);

  const showTooltip = () => {
    setActive(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setActive(false);
    }, 3000);
  }, [active]);

  return (
    <div className={cx('tooltip-wrapper')} onClick={showTooltip}>
      {props.children}
      {active && <div className={cx('tooltip-tip-top')}>{props.content}</div>}
    </div>
  );
};
export default Tooltip;
