import classnames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import styles from '../../styles/layout.module.scss';
import Dashboard from '../Icons/Dashboard';
import Tokens from '../Icons/Tokens';
import Users from '../Icons/Users';
import Games from '../Icons/Games';
import Setting from '../Icons/SettingIcon';
import { ReactNode, useState } from 'react';
import Collapse from '../UI/Collapse';

const cx = classnames.bind(styles);
export default function Sidebar() {
  const [collapse, setCollapse] = useState('');

  const list1 = [
    { icon: <Dashboard />, title: 'Dashboard', link: ROUTES.DASHBOARD },
    { icon: <Users />, title: 'Users', link: ROUTES.USER.LIST },
    {
      icon: <Tokens />,
      title: 'Transactions',
      children: [
        {
          title: 'Deposits',
          link: ROUTES.TRANSACTIONS.DEPOSITS
        },
        {
          title: 'Withdraws',
          link: ROUTES.TRANSACTIONS.WITHDRAWS
        },
        {
          title: 'Internal transactions',
          link: ROUTES.TRANSACTIONS.INTERNAL_TX
        },
        {
          title: 'Methods',
          link: ROUTES.TRANSACTIONS.METHODS
        },
        {
          title: 'Wallet Address',
          link: ROUTES.TRANSACTIONS.WALLET_ADDRESS
        }
      ]
    },
    {
      icon: <Tokens />,
      title: 'Accounting',
      children: [
        {
          title: 'Credits',
          link: ROUTES.ACCOUNTING.CREDITS
        },
        {
          title: 'Debits',
          link: ROUTES.ACCOUNTING.DEBITS
        },
        {
          title: "User's Debts",
          link: ROUTES.ACCOUNTING.USER_DEBTS
        },
        {
          title: 'Balance',
          link: ROUTES.ACCOUNTING.BALANCE
        }
      ]
    },

    // { icon: <Tokens />, title: 'Tokens', link: ROUTES.TOKENS },
    { icon: <Games />, title: 'Games', link: ROUTES.GAMES },
    { icon: <Games />, title: 'Affiliates', link: ROUTES.AFFILIATES },
    { icon: <Games />, title: 'Communication', link: ROUTES.COMMUNICATION },
    { icon: <Setting />, title: 'Setting', link: ROUTES.SETTING }
  ];

  return (
    <div className={cx('sidebar-root')}>
      <div>
        {list1.map((item, index) =>
          !item.children ? (
            <MenuItem key={item.title + index} {...item} />
          ) : (
            <>
              <div
                className={cx('menu-collapse', item.title === collapse ? 'active' : '')}
                onClick={() => {
                  setCollapse(item.title === collapse ? '' : item.title);
                }}>
                <div className={cx('title')}>
                  {item.icon} <p>{item.title}</p>
                </div>

                <div className={cx(item.title !== collapse ? 'arrow-down' : 'inital-state')}>
                  {'>'}
                </div>
              </div>
              <Collapse collapsed={collapse === item.title}>
                {item.children.map((childItem, index) => (
                  <MenuItem key={childItem.title + index} {...childItem} />
                ))}
              </Collapse>
            </>
          )
        )}
      </div>
    </div>
  );
}

interface MenuItemPropsType {
  link: string;
  title: string;
  icon?: ReactNode;
}
const MenuItem = ({ link, title, icon }: MenuItemPropsType) => {
  return (
    <NavLink
      end
      to={link}
      key={title}
      className={({ isActive }) => cx('nav-link', isActive && 'activated')}>
      <div className={cx('menu-item-container')}>
        {icon}
        <p className={cx('menu-item-title')}>{title}</p>
      </div>
    </NavLink>
  );
};
