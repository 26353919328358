import clasnames from 'classnames/bind';
import DatePicker from 'react-datepicker';
import '../../styles/react-date-picker.scss';
import styles from '../../styles/UI/date-picker.module.scss';
import CalendarIcon from '../Icons/CalendarIcon';

const cx = clasnames.bind(styles);

interface PropsType {
  placeholder?: string;
  onChange: (date: any) => void;
  selected: any;
}

export default function DatePickerUI({ placeholder, onChange, selected }: PropsType) {
  return (
    <div className={cx('date-picker-container')}>
      <DatePicker placeholderText={placeholder} onChange={onChange} selected={selected} />
      <div className={cx('date-picker-expand-icon')}>
        <CalendarIcon />
      </div>
    </div>
  );
}
