export const ROUTES = {
  ERROR: '*',
  HOME: '/',
  DASHBOARD: '/dashboard',
  GAMES: '/games',
  USER: {
    LIST: '/users',
    PORTFOLIO: '/portfolio/:id'
  },
  SETTING: '/setting',
  TOKENS: '/tokens',

  DEPOSITS: '/deposits',
  WITHDRAWS_DETAIL: '/withdraws/:id',
  DEPOSITS_DETAIL: '/deposits/:id',
  TRANSACTIONS: {
    PORTFOLIO: '/portfolio',
    DEPOSITS: '/deposits',
    WITHDRAWS: '/withdraws',
    INTERNAL_TX: '/internal-transactions',
    METHODS: '/methods',
    WALLET_ADDRESS: '/wallet-address'
  },
  ACCOUNTING: {
    CREDITS: '/credits',
    DEBITS: '/debits',
    USER_DEBTS: '/user-debts',
    BALANCE: '/balance'
  },
  AFFILIATES: '/affiliates',
  COMMUNICATION: '/communication'
};
