import { ReactNode } from 'react';
import { ROUTES } from '../constant/routes';
import ErrorPage from '../pages/error';
import HomePage from '../pages/home';
import Dashboard from '../pages/dashboard';
import Games from '../pages/games';
import Setting from '../pages/setting';
import Tokens from '../pages/tokens';
import Users from '../pages/Users/users';
import DepositsPage from '../pages/deposits.tsx/index';
import DepositsDetailPage from '../pages/deposits.tsx/detail';
import UserPortfolio from '../components/Users/ActionUser/UserPortfolio';
import WithdrawsPage from '../pages/withdraws.tsx';
import WithdrawsDetailPage from '../pages/withdraws.tsx/detail';

interface Route {
  path: string;
  element: ReactNode;
}

export const routes = [
  { path: ROUTES.ERROR, element: <ErrorPage /> },
  { path: ROUTES.HOME, element: <HomePage /> },
  { path: ROUTES.DASHBOARD, element: <Dashboard /> },
  { path: ROUTES.USER.LIST, element: <Users /> },
  { path: ROUTES.USER.PORTFOLIO, element: <UserPortfolio /> },
  { path: ROUTES.TOKENS, element: <Tokens /> },
  { path: ROUTES.GAMES, element: <Games /> },
  { path: ROUTES.SETTING, element: <Setting /> },
  { path: ROUTES.DEPOSITS, element: <DepositsPage /> },
  { path: ROUTES.DEPOSITS_DETAIL, element: <DepositsDetailPage /> },
  { path: ROUTES.USER.PORTFOLIO, element: <UserPortfolio /> },
  { path: ROUTES.TRANSACTIONS.WITHDRAWS, element: <WithdrawsPage /> },
  { path: ROUTES.WITHDRAWS_DETAIL, element: <WithdrawsDetailPage /> }
] as Route[];
