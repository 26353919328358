import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import moment from 'moment';
import { useState } from 'react';
import { UserQuery } from 'user-identity-js/lib/dto/users.query';
import useMetaverse from '../hooks/useMetaverse';
import { userIdentity } from '../services/userIdentity';

interface FilterType {
  id: string | undefined;
  name: string | undefined;
  currency: string | undefined;
  network: string | undefined;
  amountFrom: string | undefined;
  amountTo: string | undefined;
  signedUpDateFrom: string | undefined;
  signedUpDateTo: string | undefined;
  search: string | undefined;
  status: string | undefined;
}

export default function useWithdraws() {
  const [withdraws, setWithdraws] = useState<Transaction[]>([]);
  const [nickNameWithdraws, setNickNameWithdraws] = useState<UserQuery[]>([]);

  const parseParams = (params: FilterType) => {
    let result = {} as any;
    if (params.currency && params.currency !== 'All') {
      result['assetSymbol'] = params.currency;
    }
    if (params.network && params.network !== 'All') {
      result['chain'] = params.network;
    }
    // if (params.amountFrom) {
    //   result['chain'] = params.amountFrom;
    // }
    // if (params.amountTo) {
    //   result = params.amountTo;
    // }
    if (params.signedUpDateFrom) {
      const fromDate = moment(params.signedUpDateFrom).format('YYYY-MM-DD');
      result['fromDate'] = new Date(fromDate).getTime() / 1000;
    }
    if (params.signedUpDateTo) {
      const toDate = moment(params.signedUpDateTo).format('YYYY-MM-DD');
      result['toDate'] = new Date(toDate).getTime() / 1000;
    }
    if (params.status && params.status !== 'All') {
      result['state'] = params.status;
    }
    return result;
  };

  const metaverseClient = useMetaverse();
  const UserIdentityClient = userIdentity();

  const fetchWithdraws = async (params: FilterType) => {
    let paramsApi = parseParams(params);
    const type = 'WITHDRAW';
    let res = await metaverseClient.getTransactions({ ...paramsApi, type });
    if (Array.isArray(res)) {
      setWithdraws(res);
    }
  };

  const fetchUserWithdraws = async () => {
    const resUser = await UserIdentityClient.getUsers({
      walletAddress:
        '0xB0Ef8b21690E215fe88bE5fa6E1d937e997726fc, 0xD75f28F89a6fa087eA94E53274381ECD691A9901'
    });
    setNickNameWithdraws(resUser);
  };

  const refreshWithdraws = () => {
    setWithdraws([]);
  };
  return { nickNameWithdraws, withdraws, fetchWithdraws, refreshWithdraws, fetchUserWithdraws };
}
