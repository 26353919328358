export default function Users() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 38 42" fill="none">
      <path
        d="M35.3333 39.5V35.3333C35.3333 33.1232 34.4554 31.0036 32.8926 29.4408C31.3298 27.878 29.2101 27 27 27H10.3333C8.1232 27 6.00358 27.878 4.44078 29.4408C2.87797 31.0036 2 33.1232 2 35.3333V39.5"
        stroke="white"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6668 18.6667C23.2692 18.6667 27.0002 14.9357 27.0002 10.3333C27.0002 5.73096 23.2692 2 18.6668 2C14.0645 2 10.3335 5.73096 10.3335 10.3333C10.3335 14.9357 14.0645 18.6667 18.6668 18.6667Z"
        stroke="white"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
