import moment from 'moment';
import { FormItem } from '../../../types/filter';
import FilterLayout from '../../Layout/FilterLayout';
// import { useTranslation } from 'react-i18next';

interface FilterParams {
  id: string | undefined;
  name: string | undefined;
  status: string | undefined;
  signedUpDateFrom: string | undefined;
  signedUpDateTo: string | undefined;
  lastLoginFrom: string | undefined;
  lastLoginTo: string | undefined;
  location: string | undefined;
}

interface PropsType {
  handleUpdateFilterParams: (params: FilterParams) => void;
}

export default function FilterUsers({ handleUpdateFilterParams }: PropsType) {
  const formItems = [
    {
      name: 'status',
      label: 'Status',
      fieldType: 'select',
      labelSpan: 3,
      labelFlex: 'flex-start',
      itemSpan: 9,
      options: [
        { id: 0, key: 'all', value: 'ALL' },
        { id: 0, key: 'all', value: 'BANNED' },
        { id: 0, key: 'all', value: 'ACTIVED' }
      ]
    },
    {
      name: 'signedUpDateFrom',
      label: 'Signed up Date',
      fieldType: 'date-picker',
      labelSpan: 5,
      labelFlex: 'flex-end',
      itemSpan: 7
    },
    {
      name: 'signedUpDateTo',
      label: 'To',
      fieldType: 'date-picker',
      labelSpan: 2,
      labelFlex: 'flex-end',
      itemSpan: 10
    },
    {
      name: 'search',
      label: 'Search',
      fieldType: 'input-search',
      labelSpan: 3,
      labelFlex: 'flex-end',
      itemSpan: 9,
      placeholder: 'Enter ID deposit, user  name'
    }
  ] as FormItem[];

  const handleFilter = (values: any) => {
    // const newFilterParams = {
    //   ...values,
    //   signedUpDateFrom: values.signedUpDateFrom
    //     ? moment(values.signedUpDateFrom).format('YYYY-MM-DD')
    //     : values.signedUpDateFrom,
    //   signedUpDateTo: values.signedUpDateTo
    //     ? moment(values.signedUpDateTo).format('YYYY-MM-DD')
    //     : values.signedUpDateTo,
    //   lastLoginFrom: values.lastLoginFrom
    //     ? moment(values.lastLoginFrom).format('YYYY-MM-DD')
    //     : values.lastLoginFrom,
    //   lastLoginTo: values.lastLoginTo
    //     ? moment(values.lastLoginTo).format('YYYY-MM-DD')
    //     : values.lastLoginTo
    // };
    // handleUpdateFilterParams(newFilterParams);
  };

  return (
    <div>
      <FilterLayout formItems={formItems} handleFilter={handleFilter} />
    </div>
  );
}
