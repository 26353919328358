import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../../styles/Components/Deposits/modal-create-deposit.module.scss';
import ModalCreate from './ModalCreate';

const cx = classNames.bind(styles);

export default function ModalCreateNewDeposit() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const ShowModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('button-create-deposits')}>
        <button className={cx('create-new-deposits')} onClick={ShowModal}>
          Create a new deposits order
        </button>
        <ModalCreate isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </div>
    </div>
  );
}
