import styles from '../../styles/Components/Deposits/filter-layout-deposits.module.scss';
import classNames from 'classnames/bind';
import ModalCreateNewDeposit from './ModalCreateNewDeposit';
import FilterDeposits from './FilterDeposits';
import { useEffect, useState } from 'react';
import useMetaverse from '../../hooks/useMetaverse';
import ListDeposits from './ListDeposits';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import BackButton from '../Layout/BackButton';
import useDeposit from '../../hooks/useDeposits';

const cx = classNames.bind(styles);
interface FilterType {
  page: number | undefined;
  pageSize: number | undefined;
  id: string | undefined;
  name: string | undefined;
  currency: string | undefined;
  network: string | undefined;
  amountFrom: string | undefined;
  amountTo: string | undefined;
  signedUpDateFrom: string | undefined;
  signedUpDateTo: string | undefined;
  search: string | undefined;
  status: string | undefined;
}

const initialFilter = {
  page: undefined,
  pageSize: undefined,
  id: undefined,
  name: undefined,
  currency: undefined,
  network: undefined,
  amountFrom: undefined,
  amountTo: undefined,
  signedUpDateFrom: undefined,
  signedUpDateTo: undefined,
  search: undefined,
  status: undefined
};

export default function Deposits() {
  const [filterParams, setFilterParams] = useState<FilterType>(initialFilter);
  // const [deposits, setDeposits] = useState<Transaction[]>([]);

  const { nickNameDeposits, deposits, fetchDeposit, refreshDeposits, fetchUserDeposit } =
    useDeposit();
  // const handleUpdateFilterParams = (newParams: any) => {
  //   setFilterParams({ ...newParams });
  // };

  // const handleChangePage = (page: number) => {
  //   setFilterParams({ ...filterParams, page });
  // };

  const metaverseClient = useMetaverse();

  const handleFilter = (filter: FilterType) => {
    setFilterParams({ ...filterParams, ...filter });
  };

  // const fetchDeposits = async () => {
  //   const res = await metaverseClient.getTransactions();
  //   setDeposits(res);
  // };
  useEffect(() => {
    fetchDeposit(filterParams);
    fetchUserDeposit();
  }, [JSON.stringify(filterParams)]);

  // useEffect(() => {
  //   fetchUserDeposit();
  // }, [JSON.stringify(filterParams)]);

  // useEffect(() => {
  //   const querySearch = {
  //     ...filterParams,
  //     id: searchParams.get('id') || undefined,
  //     username: searchParams.get('fromAccount') || undefined
  //   };
  //   handleFilter(querySearch);
  // }, [searchParams]);

  // useEffect(() => {
  //   fetchDeposits();
  // }, []);

  return (
    <div className={cx('container')}>
      <BackButton />
      <div className={cx('label-deposit')}>Deposits</div>
      <FilterDeposits handleUpdateFilterParams={handleFilter} />
      <ModalCreateNewDeposit />
      <ListDeposits
        // handleChangePage={handleChangePage}
        deposits={deposits}
        filterParams={filterParams}
        // userIdentity={[]}
        // pagination={{ page, pageSize, total: { total } }}
      />
    </div>
  );
}
