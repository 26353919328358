export default function ArrowRightCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 28 28" fill="none">
      <g clipPath="url(#clip0_612_2379)">
        <path
          d="M13.9997 25.6667C20.443 25.6667 25.6663 20.4434 25.6663 14C25.6663 7.55672 20.443 2.33337 13.9997 2.33337C7.55635 2.33337 2.33301 7.55672 2.33301 14C2.33301 20.4434 7.55635 25.6667 13.9997 25.6667Z"
          stroke="#5C89FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 18.6667L18.6667 14L14 9.33337"
          stroke="#5C89FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33301 14H18.6663"
          stroke="#5C89FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_612_2379">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
