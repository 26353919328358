import classNames from 'classnames/bind';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import useMetaverse from '../../hooks/useMetaverse';
import styles from '../../styles/Components/Deposits/modal-create-deposit.module.scss';
import CancelIcon from '../Icons/CancelIcon';
import { toast } from 'react-toastify';

const cx = classNames.bind(styles);

const ModalCreate = ({
  isModalOpen,
  setIsModalOpen
}: {
  isModalOpen: boolean;
  setIsModalOpen: any;
}) => {
  const [openModalScan, setOpenModalScan] = useState(false);
  const [inputHash, setInputHash] = useState('');
  const [selectNetwork, setSelectNetwork] = useState('');
  const [depositDetails, setDepositsDetails] = useState<Transaction>();
  const [networks, setNetworks] = useState<(string | undefined)[]>();
  const metaverseDetail = useMetaverse();

  const showModalOpenScan = () => {
    setOpenModalScan(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    setOpenModalScan(false);
  };

  const handleContinueModal = () => {
    setIsModalOpen(false);
    setOpenModalScan(false);
    toast.success('Create a new deposit order successful!');
  };

  const handleSelect = (e: string) => {
    setSelectNetwork(e);
  };

  const data = [
    {
      key: 'name',
      name: 'User Name',
      data: depositDetails?.fromAccount
    },
    {
      key: 'network',
      name: 'Network',
      data: depositDetails?.fromChain
    },
    {
      key: 'amount',
      name: 'Amount',
      data: depositDetails?.assetAmount
    },
    {
      key: 'currency',
      name: 'Currency',
      data: depositDetails?.assetSymbol
    },
    {
      key: 'fromAddress',
      name: 'Form Address',
      data: depositDetails?.fromAccount
    },
    {
      key: 'toAddress',
      name: 'To Address',
      data: depositDetails?.toAccount
    },
    {
      key: 'hash',
      name: 'Transaction Hash',
      data: depositDetails?.hash
    }
  ];

  const fetchDataDeposits = async () => {
    const res = await metaverseDetail.getTransactions();
    const foundDeposit = res.find((findHash) => findHash.hash === inputHash);
    setDepositsDetails(foundDeposit);
  };

  const fetchNetwork = async () => {
    const resNetwork = await metaverseDetail.getSupportChain();
    setNetworks(resNetwork);
  };

  useEffect(() => {
    fetchNetwork();
  }, [JSON.stringify(depositDetails)]);

  useEffect(() => {
    fetchDataDeposits();
  }, [JSON.stringify(networks)]);

  return isModalOpen
    ? ReactDOM.createPortal(
        <>
          {!openModalScan ? (
            <div className={cx('container')}>
              <div className={cx('background')} />
              <form className={cx('modal-form')}>
                <div className={cx('modal-header')}>
                  <span>Create a new deposit order</span>
                  <CancelIcon />
                </div>
                <div className={cx('modal-body')}>
                  <label>Transaction Hash</label>
                  <input
                    placeholder="Please enter here"
                    value={inputHash}
                    // key="hash"
                    onChange={(e) => setInputHash(e.target.value)}
                  />
                  <label>Network</label>
                  <select value={selectNetwork} onChange={(e) => handleSelect(e.target.value)}>
                    {networks &&
                      networks.map((data: any) => {
                        return (
                          <>
                            <option style={{ color: 'black' }} key={data}>
                              {data}
                            </option>
                          </>
                        );
                      })}
                  </select>
                </div>
                <div className={cx('modal-button')}>
                  <button onClick={handleCancel} className={cx('button-cancel')}>
                    Cancel
                  </button>
                  <button onClick={showModalOpenScan} className={cx('button-ok')}>
                    Scan
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <>
              <div className={cx('container')}>
                <div className={cx('background')} />
                <form className={cx('modal-form-scan')}>
                  <div className={cx('modal-header-scan')}>
                    <span>Create a new deposit order</span>
                    <CancelIcon />
                  </div>
                  <div className={cx('modal-body-scan')}>
                    {data.length > 0 &&
                      data.map((data: any) => {
                        return (
                          <div key={data.id} className={cx('detail-deposit-data')}>
                            <div className={cx('detail-deposit-infor')}>{data.name}</div>
                            <div className={cx('detail-deposit-infor-item')}>{data.data}</div>
                            <div className={cx('underline')} />
                          </div>
                        );
                      })}
                    <div className={cx('modal-button-scan')}>
                      <button onClick={handleCancelModal} className={cx('button-cancel-scan')}>
                        Cancel
                      </button>
                      <button onClick={handleContinueModal} className={cx('button-ok-scan')}>
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </>,
        document.body
      )
    : null;
};

export default ModalCreate;
