import moment from 'moment';
import DetailGamingHistory from './DetailGamingHistory';
import Bets from '../../Icons/BetsLogo';
import Wins from '../../Icons/WinsLogo';
import Losses from '../../Icons/LossesLogo';
import TotalWagered from '../../Icons/TotalWagered';
import TotalProfit from '../../Icons/TotalProfit';
import MaxProfit from '../../Icons/MaxProfit';
// import { useTranslation } from 'react-i18next';

interface FilterParams {
  bets: string | undefined;
  wins: string | undefined;
  losses: string | undefined;
  totalWagereds: string | undefined;
  totalProfit: string | undefined;
  maxProfit: string | undefined;
  icon: string | undefined;
}

interface PropsType {
  // handleUpdateFilterParams: (params: FilterParams) => void;
}

export default function FilterUsers() {
  // const { t } = useTranslation();

  const formItems = {
    row1: {
      span: 'Crash',
      col1: [
        {
          name: 'bets',
          label: 'Bets',
          fieldType: 'span',
          coinNumber: 56,
          icon: <Bets></Bets>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'wins',
          label: 'Wins',
          fieldType: 'span',
          coinNumber: 56,
          icon: <Wins></Wins>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'losses',
          label: 'Losses',
          fieldType: 'span',
          coinNumber: 56,
          icon: <Losses></Losses>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        }
      ],
      col2: [
        {
          name: 'totalWagereds',
          label: 'Total Wagered',
          fieldType: 'select',
          coinNumber: '2,180 BBET',
          icon: <TotalWagered></TotalWagered>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'totalProfit',
          label: 'Total Profit',
          fieldType: 'select',
          coinNumber: '1,880 BBET',
          icon: <TotalProfit></TotalProfit>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'maxProfit',
          label: 'Max Profit',
          fieldType: 'select',
          coinNumber: '300 BBET',
          icon: <MaxProfit></MaxProfit>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        }
      ]
    },
    row2: {
      span: 'Classic Dice',
      col1: [
        {
          name: 'bets',
          label: 'Bets',
          fieldType: 'span',
          coinNumber: 56,
          icon: <Bets></Bets>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'wins',
          label: 'Wins',
          fieldType: 'span',
          coinNumber: 56,
          icon: <Wins></Wins>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'losses',
          label: 'Losses',
          fieldType: 'span',
          coinNumber: 56,
          icon: <Losses></Losses>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        }
      ],
      col2: [
        {
          name: 'totalWagereds',
          label: 'Total Wagered',
          fieldType: 'select',
          coinNumber: '2,180 BBET',
          icon: <TotalWagered></TotalWagered>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'totalProfit',
          label: 'Total Profit',
          fieldType: 'select',
          coinNumber: '1,880 BBET',
          icon: <TotalProfit></TotalProfit>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'maxProfit',
          label: 'Max Profit',
          fieldType: 'select',
          coinNumber: '300 BBET',
          icon: <MaxProfit></MaxProfit>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        }
      ]
    },
    row3: {
      span: 'Ring of Fortune',
      col1: [
        {
          name: 'bets',
          label: 'Bets',
          fieldType: 'span',
          coinNumber: 56,
          icon: <Bets></Bets>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'wins',
          label: 'Wins',
          fieldType: 'span',
          coinNumber: 56,
          icon: <Wins></Wins>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'losses',
          label: 'Losses',
          fieldType: 'span',
          coinNumber: 56,
          icon: <Losses></Losses>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        }
      ],
      col2: [
        {
          name: 'totalWagereds',
          label: 'Total Wagered',
          fieldType: 'select',
          coinNumber: '2,180 BBET',
          icon: <TotalWagered></TotalWagered>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'totalProfit',
          label: 'Total Profit',
          fieldType: 'select',
          coinNumber: '1,880 BBET',
          icon: <TotalProfit></TotalProfit>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'maxProfit',
          label: 'Max Profit',
          fieldType: 'select',
          coinNumber: '300 BBET',
          icon: <MaxProfit></MaxProfit>,
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        }
      ]
    }
  } as any;

  return (
    <div>
      <DetailGamingHistory formItems={formItems} />
    </div>
  );
}
