import MetaverseClient from 'metaverse-js';
import { ENV } from '../constant/config';

export function metaverse(jwt: string) {
  const metaverseClient = new MetaverseClient({
    baseUrl: `${ENV.BASE_URL}/metaverse`,
    baseFunctionUrl: `${ENV.BASE_FUNCTION_URL}`
  });
  metaverseClient.setTokenId(jwt);

  return metaverseClient;
}
