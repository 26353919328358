import classNames from 'classnames/bind';
import styles from '../../styles/Components/Withdraws/withdraws-detail.module.scss';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DATE_FORMAT } from '../../constant/date';
import useMetaverse from '../../hooks/useMetaverse';
import BackButton from '../Layout/BackButton';

const cx = classNames.bind(styles);

export default function WithdrawsDetail() {
  const { id } = useParams();
  const metaverseDetail = useMetaverse();
  const [withdrawsDetail, setWithdrawsDetail] = useState<Transaction>();
  const [disableButton, setDisableButton] = useState(true);

  const fetchDataDeposits = async () => {
    const res = await metaverseDetail.getTransactions();
    const foundId = res.find((findId) => findId.id === id);
    setWithdrawsDetail(foundId);
  };

  useEffect(() => {
    fetchDataDeposits();
  }, []);

  const data = [
    {
      key: 'nickName',
      name: 'Nick Name',
      data: withdrawsDetail?.id
    },
    {
      key: 'userIdentifier',
      name: 'User Identifier',
      data: withdrawsDetail?.fromAccount
    },
    {
      key: 'network',
      name: 'Network',
      data: withdrawsDetail?.fromChain
    },
    {
      key: 'amount',
      name: 'Amount',
      data: withdrawsDetail?.assetAmount
    },
    {
      key: 'currency',
      name: 'Currency',
      data: withdrawsDetail?.assetSymbol
    },
    {
      key: 'status',
      name: 'Status',
      data: withdrawsDetail?.state
    },
    {
      key: 'createdDate',
      name: 'Created Date',
      data: moment(withdrawsDetail?.createdAt).format(DATE_FORMAT)
    },
    {
      key: 'fromAddress',
      name: 'Form Address',
      data: withdrawsDetail?.fromAccount
    },
    {
      key: 'toAddress',
      name: 'To Address',
      data: withdrawsDetail?.toAccount
    },
    {
      key: 'hash',
      name: 'Transaction Hash',
      data: withdrawsDetail?.hash
    }
  ];

  const handleDisableButton = () => {
    setDisableButton(false);
  };
  return (
    <div className={cx('container')}>
      <div className={cx('withdraws-detail')}>
        <BackButton />
        <div className={cx('label-accounting')}>Deposits Detail</div>

        <form className={cx('detail-withdraws-form')}>
          <div className={cx('detail-header-item')}>
            <label style={{ paddingLeft: 15 }}>Withdraws Detail</label>
          </div>

          <div>
            {data.length > 0 &&
              data.map((data: any) => {
                return (
                  <div key={data.id} className={cx('detail-withdraws-data')}>
                    <div className={cx('detail-withdraws-infor')}>{data.name}</div>
                    <div className={cx('detail-withdraws-infor-item')}>{data.data}</div>
                    <div className={cx('underline')} />
                  </div>
                );
              })}
          </div>
          <u className={cx('link-item')}>View balance history</u>
          <div className={cx('btn')}>
            {disableButton && (
              <>
                <button className={cx('reject-btn')} onClick={handleDisableButton}>
                  Reject
                </button>
                <button className={cx('approve-btn')} onClick={handleDisableButton}>
                  Approve
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
