import { useEffect, useState } from 'react';
import useMetaverse from '../../hooks/useMetaverse';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import classNames from 'classnames/bind';
import styles from '../../styles/Components/Withdraws/list-withdraws.module.scss';
import FilterWithDraws from './FilterWithDraws';
import ListWithdraws from './ListWithdraws';
import Star from '../Icons/Star';
import BackButton from '../Layout/BackButton';
import useWithdraws from '../../hooks/useWithdraws';

const cx = classNames.bind(styles);
interface FilterType {
  id: string | undefined;
  name: string | undefined;
  currency: string | undefined;
  network: string | undefined;
  amountFrom: string | undefined;
  amountTo: string | undefined;
  signedUpDateFrom: string | undefined;
  signedUpDateTo: string | undefined;
  search: string | undefined;
  status: string | undefined;
}

const initialFilter = {
  id: undefined,
  name: undefined,
  currency: undefined,
  network: undefined,
  amountFrom: undefined,
  amountTo: undefined,
  signedUpDateFrom: undefined,
  signedUpDateTo: undefined,
  search: undefined,
  status: undefined
};

export default function Deposits() {
  const [filterParams, setFilterParams] = useState<FilterType>(initialFilter);
  // const [deposits, setDeposits] = useState<Transaction[]>([]);

  const { nickNameWithdraws, withdraws, fetchWithdraws, refreshWithdraws, fetchUserWithdraws } =
    useWithdraws();
  // const handleUpdateFilterParams = (newParams: any) => {
  //   setFilterParams({ ...newParams });
  // };

  // const handleChangePage = (page: number) => {
  //   setFilterParams({ ...filterParams, page });
  // };

  const handleFilter = (filter: FilterType) => {
    setFilterParams({ ...filterParams, ...filter });
  };

  useEffect(() => {
    fetchWithdraws(filterParams);
  }, [JSON.stringify(filterParams)]);

  // useEffect(() => {
  //   const querySearch = {
  //     ...filterParams,
  //     id: searchParams.get('id') || undefined,
  //     username: searchParams.get('fromAccount') || undefined
  //   };
  //   handleFilter(querySearch);
  // }, [searchParams]);

  return (
    <div className={cx('container')}>
      <BackButton />
      <div className={cx('header-label')}>
        <div className={cx('label-withdraw')}>Withdraws</div>

        <div style={{ color: '#FFD700' }}>
          <Star /> 3 withdraws are waitting to be approved
        </div>
      </div>
      <FilterWithDraws handleUpdateFilterParams={handleFilter} />
      <div className={cx('distance')}></div>
      <ListWithdraws withdraws={withdraws} filterParams={filterParams} />
    </div>
  );
}
