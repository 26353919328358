import React from 'react';
export default function MaxProfit() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 25 24" fill="none">
      <g clipPath="url(#clip0_133_5901)">
        <path
          d="M19.3362 11.0091L12.5759 2.57275L5.34106 10.67L19.3362 11.0091Z"
          stroke="#F361BA"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0462 21.1129L12.2858 12.6766L5.05103 20.7738L19.0462 21.1129Z"
          stroke="#F361BA"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_133_5901">
          <rect
            width="22.0538"
            height="23.9987"
            fill="white"
            transform="matrix(0.0286876 -0.999588 0.999707 0.0242233 0 22.4897)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
