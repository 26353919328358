import React from 'react';
import styles from '../../styles/UI/collapse.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface PropsType {
  collapsed: boolean;
  children: React.ReactNode;
}

export default function Collapse({ collapsed, children }: PropsType) {
  return (
    <div className={cx('panel-container', collapsed && 'panel-collapse-container')}>{children}</div>
  );
}
