export default function Tokens() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 50 50" fill="none">
      <g clipPath="url(#clip0_20_667)">
        <path
          d="M37.5 8.33325H12.5C10.1989 8.33325 8.33337 10.1987 8.33337 12.4999V37.4999C8.33337 39.8011 10.1989 41.6666 12.5 41.6666H37.5C39.8012 41.6666 41.6667 39.8011 41.6667 37.4999V12.4999C41.6667 10.1987 39.8012 8.33325 37.5 8.33325Z"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.25 18.75H18.75V31.25H31.25V18.75Z"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.75 2.08325V8.33325"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.25 2.08325V8.33325"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.75 41.6667V47.9167"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.25 41.6667V47.9167"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.6666 18.75H47.9166"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.6666 29.1667H47.9166"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.08337 18.75H8.33337"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.08337 29.1667H8.33337"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_667">
          <rect width={50} height={50} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
