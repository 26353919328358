export default function ArrowBackIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 30 30" fill="none">
      <path
        d="M23.75 15H6.25"
        stroke="#5C89FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 23.75L6.25 15L15 6.25"
        stroke="#5C89FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
