import classNames from 'classnames/bind';
import React from 'react';
import styles from '../../styles/layouts/container.module.scss';

const cx = classNames.bind(styles);

interface PropsType {
  children: React.ReactNode;
}

const Container = ({ children }: PropsType) => {
  return <div className={cx('container')}>{children}</div>;
};

export default Container;
