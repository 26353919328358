import { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import shortenAddress from '../../../utils/shortenAddress';
import AdminTable from '../../UI/AdminTable';
import stylesCommon from '../../../styles/common.module.scss';
import { DATE_FORMAT } from '../../../constant/date';
import { linkToUserDetail } from '../../../utils/linktoDetailPage';
import { TransactionsRes } from '../../../types/user';
import { ROUTES } from '../../../constant/routes';
import Pen from '../../Icons/PenIcon';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

const cxCommon = classNames.bind(stylesCommon);
interface PropsType {
  handleChangePage: (page: number) => void;
  users: TransactionsRes[];
  // pagination: { page: number; pageSize: number; total: number };
}

const ListUsers = ({ users }: PropsType) => {
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const columns = [
    {
      title: 'User Identifer',
      dataIndex: 'identifier',
      key: 'identifier',
      line: 1
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      line: 1
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      line: 1
    },
    {
      title: 'Sign up Date',
      dataIndex: 'signUpDate',
      key: 'signUpDate',
      line: 1
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      line: 1
    },

    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      line: 1
    }
  ] as any[];

  const updateDataTable = () => {
    let dataTable = users.map((user) => {
      return {
        key: user.id,
        idUser: [user.id].join().slice(0, 3) + ' ... ' + [user.id].join().slice(33, 36),
        nickName: (
          <Link
            className={cxCommon('text-violet', 'text-decoration')}
            to={`${ROUTES.USER.PORTFOLIO}`}>
            {user.nickName}
          </Link>
        ),
        email: user.email,
        status: user.status,
        signUpDate: user.signedInAt ? moment.unix(user.signedInAt).format(DATE_FORMAT) : '',
        lastLogin: user.signedUpAt ? moment.unix(user.signedUpAt).format(DATE_FORMAT) : '',
        walletAddress: user.walletAddress,
        address: user.address,
        firstName: user.firstName,
        lastName: user.lastName,
        identifier: shortenAddress(user.identifier),
        // [user.identifier].join().slice(0, 3) + ' ... ' + [user.identifier].join().slice(33, 36),
        state: user.state,
        edit: (
          <Link
            className={cxCommon('text-violet')}
            to={`${ROUTES.TRANSACTIONS.PORTFOLIO}/${user.id}`}>
            <Pen /> Edit
          </Link>
        )
      };
    });
    setDataTable(dataTable);
  };

  useEffect(() => {
    updateDataTable();
  }, [JSON.stringify(users)]);

  const changePage = (page: number) => {
    setPage(page);
  };

  return (
    <div>
      <AdminTable
        columns={columns}
        dataTable={dataTable}
        pagination={{
          total: 30,
          pageSize: 2,
          page: 1
        }}
        // changePage={changePage}
      />
    </div>
  );
};
export default ListUsers;
