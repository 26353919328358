import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/layouts/back-button.module.scss';
import ArrowLeft from '../Icons/ArrowLeft';

const cx = classNames.bind(styles);

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={cx('wrapper')}>
        <div className={cx('back-button')} onClick={() => navigate(-1)}>
          <ArrowLeft /> Back
        </div>
      </div>
    </>
  );
};

export default BackButton;
