import classNames from 'classnames/bind';
import axios from 'axios';
import moment, { Moment } from 'moment';
import React, { useState, useRef, useEffect } from 'react';
import stylesCommon from '../../../styles/common.module.scss';
import styles from '../../../styles/Users/DetailPortfolio.module.scss';
import Delete from '../../Icons/DeleteIcon';
import { Controller, useForm } from 'react-hook-form';
import DatePickerUI from '../../UI/DatePickerUI';
import GamimgHistory from './GamingHistory';
import userIdentity from '../../../hooks/useIdentity';
import { TransactionsRes } from '../../../types/user';
import Input from '../../UI/Input';
import Select from '../../UI/Select';
import { ROUTES } from '../../../constant/routes';
import { render } from '@testing-library/react';
import { useParams } from 'react-router-dom';
import { setValues } from 'framer-motion/types/render/utils/setters';
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);

interface Option {
  id: number;
  key: string;
  value: string;
}

type FieldType = 'input' | 'date-picker' | 'input-iconDelete' | 'select';

interface FormItem {
  value: string;
  name: string;
  data: any;
  label: string;
  fieldType: FieldType;
  placeholder?: string;
  labelSpan: {
    md: number | string;
    xs: number | string;
  };
  itemSpan: {
    md: number | string;
    xs: number | string;
  };
  options?: Option[];
  onChangeDate?: (date: Moment) => void;
}

interface PropsType {
  formItems: any;
  handleFilter: (values: any) => void;
  dataDetail: any;
}

export default function DetailPortfolio({ formItems, handleFilter, dataDetail }: PropsType) {
  const { id } = useParams();
  const [dataNickName, setDataNickName] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useRef(null);
  // if (dataDetail) {
  //   setData(dataDetail);
  // }
  const {
    resetField,
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({});

  const watchAll = watch();

  const onSubmit = async (data: any) => {
    const data1 = await fetch(`${ROUTES.TRANSACTIONS.PORTFOLIO}/${id}`);
  };

  useEffect(() => {
    handleFilter(watchAll);
  }, [JSON.stringify(watchAll)]);
  const handleFinish = (values: any) => {
    handleFilter(values);
  };

  const handleModal = () => {
    if (dataDetail?.nickName && dataDetail.nickName != watch('nickName')) {
      setIsModalOpen(true);
    }
  };
  useEffect(() => {
    handleModal();
  });

  const identity = userIdentity();

  const handleSaveData = async () => {
    if (id) {
      const res: any = await identity.updateUser(id, { nickName: watch('nickName') });
      alert('Updata Successful!');
      setIsModalOpen(false);
    }
  };

  const handleDelete = (name: string) => {
    return resetField(name);
  };

  const renderFormItem = (item: FormItem, data: any) => {
    switch (item.fieldType) {
      case 'input': {
        return (
          <div>
            <Controller
              name={item.name}
              control={control}
              defaultValue={data ? data : ''}
              render={() => (
                <Input readonly={true} placeholder={item.placeholder} {...register(item.name)} />
              )}
            />
          </div>
        );
      }
      case 'input-iconDelete': {
        return (
          <div>
            <Controller
              name={item.name}
              control={control}
              defaultValue={data ? data : ''}
              render={() => (
                <Input
                  placeholder={item.placeholder}
                  expand={
                    <div onClick={() => handleDelete(item.name)}>
                      <Delete />
                    </div>
                  }
                  {...register(item.name)}
                />
              )}
            />
          </div>
        );
      }
      case 'select': {
        return <Select options={data ? data : '' || []} {...register(item.name)}></Select>;
      }
      case 'date-picker': {
        return (
          <Controller
            control={control}
            name={item.name}
            defaultValue={data ? data : ''}
            render={({ field }) => (
              <DatePickerUI
                placeholder={item.placeholder}
                onChange={(date) => field.onChange(date)}
                selected={field.value}
              />
            )}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cx('container')}>
          {Object.keys(formItems).map((tdNumber) => {
            return (
              <td key={tdNumber} {...formItems[tdNumber]?.span}>
                {formItems[tdNumber]?.items?.map((formItem: FormItem) => (
                  <React.Fragment key={formItem.name}>
                    <>
                      {formItem?.data !== undefined && (
                        <tr>
                          <td>
                            <p>{formItem.label}</p>
                          </td>
                          <td>{renderFormItem(formItem, formItem?.data)}</td>
                        </tr>
                      )}
                    </>
                  </React.Fragment>
                ))}
              </td>
            );
          })}
        </div>

        <GamimgHistory />
        <div className={cx('container-button')}>
          {isModalOpen ? (
            <button className={cx('container-button-on')} onClick={handleSaveData}>
              Save change
            </button>
          ) : (
            <button className={cx('container-button-off')}>Save change</button>
          )}
        </div>
      </form>
    </div>
  );
}
