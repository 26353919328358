import React, { useState, useEffect } from 'react';
import Container from '../Layout/Container';
import FilterUser from './Filters/FilterUsers';
import TableUser from './ActionUser/ListUsers';
import userIdentity from '../../hooks/useIdentity';
import { UserIdentityClient } from 'user-identity-js';
import { TransactionsRes } from '../../types/user';

interface FilterParams {
  page: number;
  pageSize: number;
  name: string | undefined;
  email: string | undefined;
  status: string | undefined;
  signedUpDate: string | undefined;
  lastLogin: string | undefined;
  refered: string | undefined;
}

const initialFilter = {
  page: 1,
  pageSize: 3,
  name: undefined,
  email: undefined,
  status: undefined,
  signedUpDate: undefined,
  lastLogin: undefined,
  refered: undefined
};

export default function Users() {
  const [filterParams, setFilterParams] = useState<FilterParams>(initialFilter);
  const handleUpdateFilterParams = (newParams: any) => {
    setFilterParams({ ...newParams, page: 1, pageSize: filterParams.pageSize });
  };
  const handleChangePage = (page: number) => {
    setFilterParams({ ...filterParams, page });
  };
  const identity = userIdentity();

  const [users, setUsers] = useState<TransactionsRes[]>([]);

  const fetchUsers = async () => {
    const res: any = await identity.getUsers();
    setUsers(res.data);
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <Container>
      <FilterUser handleUpdateFilterParams={handleUpdateFilterParams} />
      <TableUser handleChangePage={handleChangePage} users={users} />
    </Container>
  );
}
