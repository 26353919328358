import styles from '../../styles/layout.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

const Header = () => {
  return (
    <div className={cx('header-root')}>
      <div className={cx('header-img')}>
        <img src="/images/logo.svg" alt="logo" />
      </div>
    </div>
  );
};

export default Header;
