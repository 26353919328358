import moment from 'moment';
import { FormItem } from '../../types/filter';
// import FilterLayout from './FilterLayout';
import FilterLayout from '../Layout/FilterLayout';
interface FilterParams {
  page: number;
  pageSize: number;
  id: string | undefined;
  name: string | undefined;
  currency: string | undefined;
  network: string | undefined;
  amountFrom: string | undefined;
  amountTo: string | undefined;
  signedUpDateFrom: string | undefined;
  signedUpDateTo: string | undefined;
  search: string | undefined;
  status: string | undefined;
}

interface PropsType {
  handleUpdateFilterParams: (params: FilterParams) => void;
}

export default function FilterDeposits({ handleUpdateFilterParams }: PropsType) {
  const formItems = [
    {
      name: 'currency',
      label: 'Currency',
      fieldType: 'select',
      labelSpan: 3,
      labelFlex: 'flex-start',
      itemSpan: 9,
      options: [
        { id: 'All', key: 'All', value: 'All' },
        { id: 'USDT', key: 'USDT', value: 'USDT' },
        { id: 'DERUNT', key: 'DERUNT', value: 'DERUNT' }
      ]
    },
    {
      name: 'amountFrom',
      label: 'Amount',
      fieldType: 'input',
      labelSpan: 5,
      labelFlex: 'flex-end',
      itemSpan: 7
    },
    {
      name: 'amountTo',
      label: 'To',
      fieldType: 'input',
      labelSpan: 2,
      labelFlex: 'flex-end',
      itemSpan: 10
    },
    {
      name: 'search',
      label: 'Search',
      fieldType: 'input-search',
      labelSpan: 3,
      labelFlex: 'flex-end',
      itemSpan: 9,
      placeholder: 'Enter ID deposit, user  name'
    },
    {
      name: 'network',
      label: 'Network',
      fieldType: 'select',
      labelSpan: 3,
      labelFlex: 'flex-start',
      itemSpan: 9,
      options: [
        { id: 'All', key: 'All', value: 'All' },
        { id: 'TRON', key: 'TRON', value: 'TRON' },
        { id: 'BSC', key: 'BSC', value: 'BSC' },
        { id: 'CHT', key: 'CHT', value: 'CHT' }
      ]
    },
    {
      name: 'signedUpDateFrom',
      label: 'Signed up Date',
      fieldType: 'date-picker',
      labelSpan: 5,
      labelFlex: 'flex-end',
      itemSpan: 7
    },
    {
      name: 'signedUpDateTo',
      label: 'To',
      fieldType: 'date-picker',
      labelSpan: 2,
      labelFlex: 'flex-end',
      itemSpan: 10
    },
    {
      name: 'status',
      label: 'Status',
      fieldType: 'select',
      labelSpan: 3,
      labelFlex: 'flex-end',
      itemSpan: 9,
      options: [
        { id: 'All', key: 'All', value: 'All' },
        { id: 'COMPLETED', key: 'COMPLETED', value: 'COMPLETED' },
        { id: 'INVALID', key: 'INVALID', value: 'INVALID' },
        { id: 'VALIDATING', key: 'VALIDATING', value: 'VALIDATING' }
      ]
    }
  ] as FormItem[];

  const handleFilter = (values: any) => {
    const newFilterParams = {
      ...values,
      signedUpDateFrom: values.signedUpDateFrom
        ? moment(values.signedUpDateFrom)
        : values.signedUpDateFrom,
      signedUpDateTo: values.signedUpDateTo ? moment(values.signedUpDateTo) : values.signedUpDateTo
    };
    handleUpdateFilterParams(newFilterParams);
  };

  return (
    <div>
      <FilterLayout formItems={formItems} handleFilter={handleFilter} />
    </div>
  );
}
