import classNames from 'classnames/bind';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styles from '../../styles/layouts/filter-layout.module.scss';
import { FormItem } from '../../types/filter';
import Search from '../Icons/Search';
import DatePickerUI from '../UI/DatePickerUI';
import Input from '../UI/Input';
import Select from '../UI/Select';

const cx = classNames.bind(styles);

interface PropsType {
  formItems: FormItem[];
  handleFilter: (values: any) => void;
}

export default function FilterLayout({ formItems, handleFilter }: PropsType) {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const onSubmit = (data: any) => console.log(data);

  const watchAll = watch();

  useEffect(() => {
    handleFilter(watchAll);
  }, [JSON.stringify(watchAll)]);

  const renderField = (item: FormItem) => {
    switch (item.fieldType) {
      case 'input': {
        return <Input placeholder={item.placeholder} {...register(item.name)} />;
      }
      case 'input-search': {
        return (
          <Input placeholder={item.placeholder} expand={<Search />} {...register(item.name)} />
        );
      }
      case 'date-picker': {
        return (
          <Controller
            control={control}
            name={item.name}
            render={({ field }) => (
              <DatePickerUI
                placeholder="DD/MM/YYYY"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
              />
            )}
          />
        );
      }
      case 'select': {
        return <Select options={item.options || []} {...register(item.name)} />;
      }
      default:
        return null;
    }
  };

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      {formItems.map((formItem) => (
        <div className={cx('form-item', 'col-3')} key={formItem.name}>
          <p className={cx('item-label', `col-${formItem.labelSpan}`, formItem.labelFlex)}>
            {formItem.label}
          </p>
          <div className={cx(`col-${formItem.itemSpan}`)}>{renderField(formItem)}</div>
        </div>
      ))}
    </form>
  );
}
