export default function TotalWagereds() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 23" fill="none">
      <g clipPath="url(#clip0_133_5879)">
        <path
          d="M12 1.95811L22 7.93063V14.3626L12 20.3351L2 14.3626V7.93063L12 1.95811Z"
          stroke="#FFD700"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20.3351V14.3626"
          stroke="#FFD700"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 7.93063L12 14.3626L2 7.93063"
          stroke="#FFD700"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 14.3626L12 7.93063L22 14.3626"
          stroke="#FFD700"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 1.95811V7.93063"
          stroke="#FFD700"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_133_5879">
          <rect width={24} height="22.0524" fill="white" transform="translate(0 0.120422)" />
        </clipPath>
      </defs>
    </svg>
  );
}
