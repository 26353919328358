import SortAscIcon from '../Icons/SortAscIcon';
import SortDescIcon from '../Icons/SortDescIcon';
import { useEffect, useState } from 'react';
import styles from '../../styles/UI/table.module.scss';
import classnames from 'classnames/bind';
import stylesCommon from '../../styles/common.module.scss';
import classNames from 'classnames/bind';
import Pagination from './Pagination';

const cx = classnames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);

type ColType = 'checkbox' | 'link-edit' | 'component' | 'status' | 'username';

interface ColumnPropsType {
  title: string;
  dataIndex: string;
  key: string;
  textAlign?: 'left' | 'right' | 'center';
  type?: ColType;
  width?: String | number;
  sorter?: boolean;
  line: number;
  render?: any;
  textWrap?: string;
  tooltip?: string;
  href?: string;
}

interface PropsType {
  columns: ColumnPropsType[];
  dataTable: any[];
  handleSort?: (field: string, order: 'asc' | 'desc') => void;
  pagination: {
    total: number;
    page: number;
    pageSize: number;
  };
  changePage?: (page: number) => void;
}

export default function AdminTable({
  columns,
  dataTable,
  handleSort,
  pagination,
  changePage
}: PropsType) {
  // console.log(dataTable);
  const [sortingField, setSortingField] = useState('');
  const [sortingOrder, setSortingOrder] = useState('asc');

  const onSortingChange = (field: string) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';
    setSortingField(field);
    setSortingOrder(order);
    handleSort && handleSort(field, order);
  };

  return (
    <div className={cx('container')}>
      <table className={cx('table-container')}>
        <tr>
          {columns.map((col, sortable) => (
            <th key={col.key} className={cx('table-header-item')}>
              <span
                style={{ cursor: sortable ? 'pointer' : 'initial', gap: 5 }}
                onClick={() => (sortable ? onSortingChange(col.key) : null)}>
                {col.title}
                {/* {sortable && sortingField !== col.key ? <SortDescIcon /> : ''} */}
                {sortable && sortingField === col.key ? (
                  <>{sortingOrder === 'asc' ? <SortDescIcon /> : <SortAscIcon />}</>
                ) : (
                  ''
                )}
              </span>
            </th>
          ))}
        </tr>
        {dataTable.length > 0 ? (
          dataTable.map((data: any) => {
            return (
              <tr key={data.key} className={cx('column-data')}>
                {columns.map((col) => (
                  <td key={col.key + data.key} className={cx('data-item')}>
                    {col.render ? col.render(data[col.key]) : data[col.key]}
                  </td>
                ))}
              </tr>
            );
          })
        ) : (
          <tr className={cx('nodata')}>
            <td colSpan={columns.length} className={cx('nodata-item')}>
              Nodata
            </td>
          </tr>
        )}
      </table>
      {changePage && dataTable.length > 0 && (
        <div className={cx('pagination')}>
          <Pagination pagination={pagination} changePage={changePage} />

          {/* <Pagination
          style={{ margin: 16 }}
          current={pagination?.currentPage}
          total={pagination?.total}
          // pageSize={pagination?.itemsPerPage}
          onChange={handleSelectPage}
        /> */}
        </div>
      )}
    </div>
  );
}
