import React from 'react';
export default function Wins() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 24 23" fill="none">
      <path
        d="M17 10.2277L12 5.6335L7 10.2277"
        stroke="#50F900"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 16.6597L12 12.0654L7 16.6597"
        stroke="#50F900"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
