import classNames from 'classnames/bind';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DATE_FORMAT } from '../../constant/date';
import useMetaverse from '../../hooks/useMetaverse';
import styles from '../../styles/Components/Deposits/deposits-detail.module.scss';
import BackButton from '../Layout/BackButton';

const cx = classNames.bind(styles);

export default function DepositsDetail() {
  const { id } = useParams();
  const metaverseDetail = useMetaverse();
  const [depositDetails, setDepositsDetails] = useState<Transaction>();

  const fetchDataDeposits = async () => {
    const res = await metaverseDetail.getTransactions();
    const foundId = res.find((findId) => findId.id === id);
    setDepositsDetails(foundId);
  };

  useEffect(() => {
    fetchDataDeposits();
  }, []);

  const data = [
    {
      key: 'nickName',
      name: 'Nick Name',
      data: depositDetails?.id
    },
    {
      key: 'userIdentifier',
      name: 'User Identifier',
      data: depositDetails?.fromAccount
    },
    {
      key: 'network',
      name: 'Network',
      data: depositDetails?.fromChain
    },
    {
      key: 'amount',
      name: 'Amount',
      data: depositDetails?.assetAmount
    },
    {
      key: 'currency',
      name: 'Currency',
      data: depositDetails?.assetSymbol
    },
    {
      key: 'status',
      name: 'Status',
      data: depositDetails?.state
    },
    {
      key: 'createdDate',
      name: 'Created Date',
      data: moment(depositDetails?.createdAt).format(DATE_FORMAT)
    },
    {
      key: 'fromAddress',
      name: 'Form Address',
      data: depositDetails?.fromAccount
    },
    {
      key: 'toAddress',
      name: 'To Address',
      data: depositDetails?.toAccount
    },
    {
      key: 'hash',
      name: 'Transaction Hash',
      data: depositDetails?.hash
    },
    {
      key: 'message',
      name: 'Message',
      data: depositDetails?.message
    }
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('deposit-detail')}>
        <BackButton />
        <div className={cx('label-accounting')}>Deposits Detail</div>

        <form className={cx('detail-deposits-form')}>
          <div className={cx('detail-header-item')}>
            <label style={{ paddingLeft: 10 }}>Deposits Detail</label>
          </div>

          <div>
            {data.length > 0 &&
              data.map((detail) => {
                return (
                  <div key={detail.key} className={cx('detail-deposit-data')}>
                    <div className={cx('detail-deposit-infor')}>{detail.name}</div>
                    <div className={cx('detail-deposit-infor-item')}>{detail.data}</div>
                    <div className={cx('underline')}></div>
                  </div>
                );
              })}
          </div>
          <u className={cx('link-item')}>View balance history</u>
        </form>
      </div>
    </div>
  );
}
