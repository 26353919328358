import { useEffect, useState } from 'react';
import { basePagination } from '../../utils/basePagination';
import styles from '../../styles/UI/pagination.module.scss';
import classnames from 'classnames/bind';
import ArrowPage from '../Icons/ArrowPage';

const cx = classnames.bind(styles);

interface PropsType {
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  changePage: (page: number) => void;
}

export default function Pagination({ pagination, changePage }: PropsType) {
  const [count, setCount] = useState(1);

  const pages = basePagination(pagination.page, count);

  useEffect(() => {
    setCount(Math.ceil((pagination?.total || 1) / (pagination?.pageSize || 1)));
  }, [pagination]);

  return (
    <div className={cx('root')}>
      <button
        className={cx('btn-prev', 'btn')}
        onClick={() => {
          changePage(pagination.page - 1 > 0 ? pagination.page - 1 : 1);
        }}
        disabled={pagination.page === 1}>
        <ArrowPage />
      </button>
      {pages?.map((i) => {
        return (
          <div
            key={i}
            className={cx('page-item', pagination.page === i && 'page-item-active')}
            onClick={() => {
              changePage(Number(i) || 1);
            }}>
            <p>{i}</p>
          </div>
        );
      })}
      <button
        className={cx('btn-next', 'btn')}
        onClick={() => {
          changePage(pagination.page + 1 < count ? pagination.page + 1 : count);
        }}
        disabled={pagination.page === count}>
        <ArrowPage />
      </button>
    </div>
  );
}
