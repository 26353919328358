import React, { useState, useEffect } from 'react';
// import _ from 'lodash';
import Container from '../../Layout/Container';
import UserPortfolioInfo from './UserPortfolioInfo';
import GamimgHistory from './GamingHistory';
import classNames from 'classnames/bind';
import styles from '../../../styles/Users/UserPortfolio.module.scss';
import stylesCommon from '../../../styles/common.module.scss';
import StatusOff from '../../Icons/StatusOff';
import StatusOn from '../../Icons/StatusOn';
import { ROUTES } from '../../../constant/routes';
import { UserIdentityClient } from 'user-identity-js';
import ArrowBack from '../../Icons/ArrowBackIcon';
import DeletePopup from '../ActionUser/DeletePopup';
import useIdentity from '../../../hooks/useIdentity';
import useGameProfile from '../../../hooks/useGameProfile';
import { TransactionsRes, GameProfileRes } from '../../../types/user';
import { Link, useParams } from 'react-router-dom';
import { bool } from 'yup';
import { boolean } from 'yup/lib/locale';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);
interface FilterParams {
  page: number;
  pageSize: number;
  name: string | undefined;
  email: string | undefined;
  status: string | undefined;
  signedUpDate: string | undefined;
  lastLogin: string | undefined;
  refered: string | undefined;
}

const initialFilter = {
  page: 1,
  pageSize: 3,
  name: undefined,
  email: undefined,
  status: undefined,
  signedUpDate: undefined,
  lastLogin: undefined,
  refered: undefined
};

export default function UserPortfolio() {
  const [filterParams, setFilterParams] = useState<FilterParams>(initialFilter);
  const [bannedUser, setBannedUser] = useState<boolean>();
  const { id } = useParams();

  const handleUpdateFilterParams = (newParams: any) => {
    setFilterParams({ ...newParams, page: 1, pageSize: filterParams.pageSize });
  };

  const identity = useIdentity();
  const gameProfile = useGameProfile();
  const [userPortfolioIdentity, setUserPortfolioIdentity] = useState<TransactionsRes[]>([]);
  const [userPortfolioGameProfile, setUserPortfolioGameProfile] = useState<GameProfileRes>();

  const fetchUserPortfolioInfo = async () => {
    if (id) {
      const resIdentity: any = await identity.getUser(id);
      setUserPortfolioIdentity(resIdentity.data);
      const resGameProfile: any = await gameProfile.getRefProfileDetail({ userId: id });
      setUserPortfolioGameProfile(resGameProfile);
      if (resIdentity?.data.status === 'BANNED') setBannedUser(true);
      else setBannedUser(false);
    }
  };
  useEffect(() => {
    fetchUserPortfolioInfo();
  }, [id]);

  const handleChangeStatus = async () => {
    setBannedUser((prev) => !prev);
    fetchChangeStatus(!bannedUser);
  };
  const fetchChangeStatus = async (bannedUser: boolean) => {
    if (id) {
      if (bannedUser) {
        const res: any = await identity.updateUser(id, { status: 'BANNED' });
      } else {
        const res: any = await identity.updateUser(id, { status: 'ACTIVED' });
      }
    }
  };
  return (
    <Container>
      <div className={cx('container')}>
        {' '}
        <Link className={cxCommon('text-decoration')} to={`${ROUTES.USER.LIST}`}>
          <ArrowBack /> Back
        </Link>
        <div className={cx('container-namePage')}> User Details</div>
        <div className={cx('container-status')}>
          <span></span>
          <div className={cx('container-status-user')} onClick={handleChangeStatus}>
            <div className={cxCommon('flex')}>
              {bannedUser ? <StatusOn /> : <StatusOff />}

              <span>Banned user</span>
            </div>{' '}
          </div>
          {/* <DeletePopup /> */}
        </div>
        <div className={cx('container-content')}>
          <div className={cx('container-header')}>{/* <span>USER US0012346</span> */}</div>
          {userPortfolioGameProfile && userPortfolioIdentity && (
            <UserPortfolioInfo
              handleUpdateFilterParams={handleUpdateFilterParams}
              userPortfolioIdentity={userPortfolioIdentity}
              userPortfolioGameProfile={userPortfolioGameProfile}
            />
          )}
        </div>
      </div>
    </Container>
  );
}
