import { GameProfileAdminClient } from 'game-profile-js';
import { ENV } from '../constant/config';

export function gameProfile(jwt: string) {
  const gameProfile = new GameProfileAdminClient({
    baseUrl: `https://api-bybet.noownerapi.com/profile/admin/`
  });
  gameProfile.setTokenId(jwt);

  return gameProfile;
}
