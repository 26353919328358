import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import moment from 'moment';
import { useState } from 'react';
import { UserQuery } from 'user-identity-js/lib/dto/users.query';
import useMetaverse from '../hooks/useMetaverse';
import { userIdentity } from '../services/userIdentity';

interface FilterType {
  id: string | undefined;
  page: number | undefined;
  pageSize: number | undefined;
  name: string | undefined;
  currency: string | undefined;
  network: string | undefined;
  amountFrom: string | undefined;
  amountTo: string | undefined;
  signedUpDateFrom: string | undefined;
  signedUpDateTo: string | undefined;
  search: string | undefined;
  status: string | undefined;
}

export default function useDeposit() {
  const [deposits, setDeposits] = useState<Transaction[]>([]);
  const [nickNameDeposits, setNickNameDeposits] = useState<UserQuery[]>([]);
  const [total, setTotal] = useState(0);

  const parseParams = (params: FilterType) => {
    let result = {} as any;
    if (params.page) {
      result['limit'] = params.page;
    }
    if (params.pageSize) {
      result['offset'] = params.pageSize;
    }
    if (params.currency && params.currency !== 'All') {
      result['assetSymbol'] = params.currency;
    }
    if (params.network && params.network !== 'All') {
      result['chain'] = params.network;
    }
    // if (params.amountFrom) {
    //   result['assetAmount'] = params.amountFrom;
    // }
    // if (params.amountTo) {
    //   result['assetAmount'] = params.amountTo;
    // }
    if (params.signedUpDateFrom) {
      const fromDate = moment(params.signedUpDateFrom).format('YYYY-MM-DD');
      result['fromDate'] = new Date(fromDate).getTime() / 1000;
    }
    if (params.signedUpDateTo) {
      const toDate = moment(params.signedUpDateTo).format('YYYY-MM-DD');
      result['toDate'] = new Date(toDate).getTime() / 1000;
    }
    if (params.status && params.status !== 'All') {
      result['state'] = params.status;
    }

    return result;
  };

  const metaverseClient = useMetaverse();
  const UserIdentityClient = userIdentity();

  const fetchDeposit = async (params: FilterType) => {
    let paramsApi = parseParams(params);
    const type = 'DEPOSIT';
    let res = await metaverseClient.getTransactions({ ...paramsApi, type });
    if (Array.isArray(res)) {
      setDeposits(res);
    }
  };

  const fetchUserDeposit = async () => {
    const resUser = await UserIdentityClient.getUsers({
      // walletAddress: '0xB0Ef8b21690E215fe88bE5fa6E1d937e997726fc'
    });
    console.log(resUser);
    setNickNameDeposits(resUser);
  };

  const refreshDeposits = () => {
    setDeposits([]);
  };
  return { nickNameDeposits, deposits, fetchDeposit, refreshDeposits, fetchUserDeposit };
}
