export default function Dashboard() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 50 50" fill="none">
      <path
        d="M10.4166 35.4167H8.33329C7.22822 35.4167 6.16842 34.9777 5.38701 34.1963C4.60561 33.4149 4.16663 32.3551 4.16663 31.25V10.4167C4.16663 9.3116 4.60561 8.25179 5.38701 7.47039C6.16842 6.68899 7.22822 6.25 8.33329 6.25H41.6666C42.7717 6.25 43.8315 6.68899 44.6129 7.47039C45.3943 8.25179 45.8333 9.3116 45.8333 10.4167V31.25C45.8333 32.3551 45.3943 33.4149 44.6129 34.1963C43.8315 34.9777 42.7717 35.4167 41.6666 35.4167H39.5833"
        stroke="#5C89FF"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 31.25L35.4167 43.75H14.5834L25 31.25Z"
        stroke="#5C89FF"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
