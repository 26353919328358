import React from 'react';
export default function TotalProfit() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 23" fill="none">
      <path
        d="M12 7.55235C16.9706 7.55235 21 6.3182 21 4.79581C21 3.27341 16.9706 2.03926 12 2.03926C7.02944 2.03926 3 3.27341 3 4.79581C3 6.3182 7.02944 7.55235 12 7.55235Z"
        stroke="#00B2FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 11.2278C21 12.753 17 13.9843 12 13.9843C7 13.9843 3 12.753 3 11.2278"
        stroke="#00B2FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 4.79581V17.6597C3 19.185 7 20.4162 12 20.4162C17 20.4162 21 19.185 21 17.6597V4.79581"
        stroke="#00B2FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
