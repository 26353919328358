import { useState, useEffect } from 'react';
import moment from 'moment';
import DetailPortfolio from './DetailPortfolio';
import { DATE_FORMAT } from '../../../constant/date';
import { TransactionsRes, GameProfileRes } from '../../../types/user';
import stylesCommon from '../../../styles/common.module.scss';
import { ROUTES } from '../../../constant/routes';
import classNames from 'classnames/bind';
const cxCommon = classNames.bind(stylesCommon);
interface FilterParams {
  id: string | undefined;
  nickName: string | undefined;
  email: string | undefined;
  walletAddress: string | undefined;
  signUpDate: string | undefined;
  referralBy: string | undefined;
  referralID: string | undefined;
  lastLogin: string | undefined;
  verified: string | undefined;
  twoFactor: string | undefined;
}
interface PropsType {
  handleUpdateFilterParams: (params: FilterParams) => void;
  userPortfolioIdentity: any;
  userPortfolioGameProfile: GameProfileRes | undefined;
}

export default function FilterUsers({
  handleUpdateFilterParams,
  userPortfolioIdentity,
  userPortfolioGameProfile
}: PropsType) {
  const [dataDetail, setDataDetail] = useState<any>();
  const [referredBy, setReferredBy] = useState({});

  const updateDataDetail = () => {
    let key = 0;
    let id = 0;
    let dataDetail = {
      key: userPortfolioIdentity?.id,
      nickName: userPortfolioIdentity?.nickName,
      verified: userPortfolioIdentity?.verifiedAt
        ? moment.unix(userPortfolioIdentity?.verifiedAt).format(DATE_FORMAT)
        : '',
      lastLogin:
        // ' 1/1/ 1990',
        userPortfolioIdentity?.signedInAt
          ? moment.unix(userPortfolioIdentity?.signedInAt).format(DATE_FORMAT)
          : '',
      signUpDate:
        // '26/5/2001',
        userPortfolioIdentity.signedUpAt
          ? moment.unix(userPortfolioIdentity?.signedUpAt).format(DATE_FORMAT)
          : '',
      walletAddress: userPortfolioIdentity?.walletAddress,
      identifier: userPortfolioIdentity?.identifier,
      role: userPortfolioIdentity?.role?.map((value: any) => ({
        id: id++,
        key: key++,
        value: value
      })),
      twoFactor: String(userPortfolioIdentity?.isEnableTwoFactor),
      accountLevel: String(userPortfolioGameProfile?.vipLevel),
      // 'Level 1',
      referredBy: userPortfolioGameProfile?.refCode,
      // 'MH495674',
      referredId: userPortfolioGameProfile?.id
      // 'MH038453'
    };

    setDataDetail(dataDetail);
  };
  useEffect(() => {
    updateDataDetail();
  }, [JSON.stringify(userPortfolioIdentity)]);

  const formItems = {
    col_1: {
      span: { xl: 8, lg: 24 },
      items: [
        {
          name: 'nickName',
          label: 'Nick Name',
          data: dataDetail?.nickName,
          fieldType: 'input-iconDelete',
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'identifier',
          label: 'User Identifier',
          data: dataDetail?.identifier,
          fieldType: 'input',
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'walletAddress',
          label: 'Wallet Address',
          data: dataDetail?.walletAddress,
          fieldType: 'input',
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'signUpDate',
          label: 'Signed up Date',
          data: dataDetail?.signUpDate,
          fieldType: 'input',
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        },
        {
          name: 'verified',
          label: ' Verified Email Date',
          data: dataDetail?.verified,
          fieldType: 'input',
          labelSpan: { md: 3, xs: 24 },
          itemSpan: { md: 7, xs: 24 }
        },
        {
          name: 'lastLogin',
          label: 'Last Login ',
          data: dataDetail?.lastLogin,
          fieldType: 'input',
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 17, xs: 24 }
        }
      ]
    },
    col_2: {
      span: { xl: 8, lg: 24 },
      items: [
        {
          name: 'role',
          label: 'Role',
          fieldType: 'select',
          data: dataDetail?.role,
          labelSpan: { md: 3, xs: 24 },
          itemSpan: { md: 7, xs: 24 }
        },
        {
          name: 'accountLevel',
          label: 'Account Level',
          data: dataDetail?.accountLevel,
          fieldType: 'input',
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 7, xs: 24 }
        },
        {
          name: 'referredBy',
          label: 'Referred by',
          data: dataDetail?.referredBy,
          fieldType: 'input',
          labelSpan: { md: 3, xs: 24 },
          itemSpan: { md: 7, xs: 24 }
        },
        {
          name: 'referredId',
          label: 'Referral ID',
          data: dataDetail?.referredId,
          fieldType: 'input',
          labelSpan: { md: 7, xs: 24 },
          itemSpan: { md: 7, xs: 24 }
        },

        {
          name: 'twoFactor',
          label: 'Two-factor authentication',
          data: dataDetail?.twoFactor,
          fieldType: 'input',
          labelSpan: { md: 3, xs: 24 },
          itemSpan: { md: 7, xs: 24 }
        }
      ]
    }
  } as any;

  const handleFilter = (values: any) => {
    const newFilterParams = {
      ...values,
      signedUpDateFrom: values.signedUpDateFrom
        ? moment(values.signedUpDateFrom).format('YYYY-MM-DD')
        : values.signedUpDateFrom,
      signedUpDateTo: values.signedUpDateTo
        ? moment(values.signedUpDateTo).format('YYYY-MM-DD')
        : values.signedUpDateTo,
      lastLoginFrom: values.lastLoginFrom
        ? moment(values.lastLoginFrom).format('YYYY-MM-DD')
        : values.lastLoginFrom,
      lastLoginTo: values.lastLoginTo
        ? moment(values.lastLoginTo).format('YYYY-MM-DD')
        : values.lastLoginTo
    };
    handleUpdateFilterParams(newFilterParams);
  };

  return (
    <div>
      {formItems && (
        <DetailPortfolio
          formItems={formItems}
          handleFilter={handleFilter}
          dataDetail={dataDetail}
        />
      )}
    </div>
  );
}
